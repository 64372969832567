.ghost-divisor{
    padding: 50px 0px;
    text-align: center;
}

.ghost-divisor img{
    width: 5.5%;
}

@media only screen and (max-width: 800px) {
    .ghost-divisor img{
        width: 8%;
    }
}