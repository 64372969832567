body{
  font-family: 'Proza Libre', sans-serif;
  color: #fff;
  background-color: black ;
}

#background_wrap {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url('./assets/back-pueblo.jpg');
  background-repeat: no-repeat;
  background-position: center;
}

a:hover{
  opacity: 0.8;
}