.pueblo-logo{
    text-align: center;
    font-size: 20px;
    max-width: 450px;
    margin: 0 auto;
}

.pueblo-logo img{
    max-width: 90%;
}

.pueblo-title h4{
    text-align: center;
    text-transform: uppercase;
}