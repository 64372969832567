.merch-item{
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}
.merch-item img{
    display: block;
    margin: 20px auto;
    max-width: 100%;
}
.merch-item a{
    display: inline-block;
}

#item-gorra{
    max-width: 60%;

}