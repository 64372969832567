.newsletter-form{
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.newsletter-form label{
    text-transform: uppercase;
    font-size: 20px;
}
.newsletter-form input{
    padding: 15px;
    font-size: 15px;

}
.newsletter-form button{
    background-color: #7c35fe;
    border: 0px;
    padding: 15px;
    font-size: 15px;
    text-transform: uppercase;
    color: white;
}
.newsletter-form button:disabled{
    opacity: 0.6;
}
.newsletter-form .newsletter-msg-error{
    color: #ff5959;
}
.newsletter-msg a{
    display: block;
    color: #c2a6f5;
}